import { clientActions, IClientActionsProps } from "../../ClientCardItem/utils/clientActions";

interface Roles {
  isAdmin: boolean;
  isAssistant: boolean;
  isAccountant: boolean;
  isTechSupport: boolean;
  isContactCenter: boolean;
}

enum ACTIONS {
  CREATE_REQUEST = "Создать обращение",
  PAID_CONSULTATION = "Списание платной консультации",
}

export const getAvailableActions = (
  { isAdmin, isAssistant, isAccountant, isTechSupport, isContactCenter }: Roles,
  isDraftClient?: boolean,
  hasUnlimitedConsultations?: boolean
): IClientActionsProps[] => {
  if (isDraftClient) return [];

  const isAdminRole: boolean = isAdmin || isTechSupport;
  const isAccountantRole: boolean = isAccountant || isAssistant;

  if (isAdminRole || isAccountantRole) {
    return clientActions.filter(({ value }: IClientActionsProps) => {
      if (isAccountantRole && value === ACTIONS.CREATE_REQUEST) return false;

      return !(hasUnlimitedConsultations && value === ACTIONS.PAID_CONSULTATION);
    });
  }

  if (isContactCenter) {
    return clientActions.filter(({ value }: IClientActionsProps) => value === ACTIONS.CREATE_REQUEST);
  }

  return [];
};
