import {
  FC,
  JSX,
  ChangeEvent,
  useState,
} from "react";
import { TypicalTaskDecisionType, SubstitutesType } from "app/types";
import { ButtonCustom } from "../../../../../ui-kit/ButtonCustom/ButtonCustom";
import { Form, Input, FormInstance, Select, Tag } from "antd";
import css from "../TaskCardActionDrawer.module.css";
import { competenceGroups } from "../../utils/groupsReassignTask";

interface IReassignTaskProps {
  form: FormInstance,
  taskUuid: string;
  onFinish: (actionBody: TypicalTaskDecisionType, isReassignTask?: boolean) => void;
  onDrawerClose: () => void;
  taskActionComment: string;
  handleTextAreaChange: (e: ChangeEvent<HTMLTextAreaElement> | string) => void;
  remainingCharsComment: number;
  isAutomaticProcessingActive: boolean;
}

const isReassignTask: boolean = true;

const { TextArea } = Input;

const ReassignTask: FC<IReassignTaskProps> = ({
  form,
  taskUuid,
  onFinish,
  onDrawerClose,
  taskActionComment,
  handleTextAreaChange,
  remainingCharsComment,
  isAutomaticProcessingActive,
}): JSX.Element => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(true);
  
  const competenceGroupsVariant: string[] | null = form.getFieldValue("competenceGroups" as any)?.length
    ? form.getFieldValue("competenceGroups" as any)
    : null;

  const reassignTaskBody: TypicalTaskDecisionType = {
    task: taskUuid,
    task_decision_type: "reassign_task",
    comment: taskActionComment,
    assign_responsible: {
      skills: competenceGroupsVariant,
    }
  };

  const onReassignTaskFinish = (): void => {
    if (isSubmitting) {
      onFinish(reassignTaskBody, isReassignTask);
      setIsSubmitting(false);
    }
  };
  
  const groupsTagRender = (props: any): JSX.Element => {
    const { value, closable, onClose } = props;
    const data: SubstitutesType | undefined = competenceGroups.find(({ key }): boolean => key === value);
    
    return (
      <Tag
        className={css.blockTag}
        closable={closable}
        onClose={onClose}
      >
        <div>{data?.label}</div>
      </Tag>
    );
  };

  return (
    <>
      <p className={css.actionDrawerDescr}>
        Вы можете переназначить это обращение и по желанию оставить комментарий
      </p>
      <Form
        className="m-0"
        name="reassignTask"
        layout="vertical"
        form={form}
        onFinish={onReassignTaskFinish}
      >
        <Form.Item
          className="mb-4"
          name="competenceGroups"
          label="Группы компетенций"
          required
        >
          <Select
            id="competenceGroups"
            size="large"
            showSearch
            allowClear
            options={competenceGroups}
            mode="multiple"
            placeholder="Выберите группы"
            tagRender={groupsTagRender}
            filterOption={(input: string, option: any) =>
              (option?.label ?? "")?.toLowerCase().includes(input.toLowerCase())
            }
          />
        </Form.Item>
        <Form.Item
          className="mb-4"
          name="reassignComment"
          label="Комментарий"
        >
          <TextArea
            id="reassignComment"
            rows={25}
            maxLength={2000}
            className="text-base font-normal leading-5 text-gray-900"
            placeholder="Ваш комментарий"
            onChange={handleTextAreaChange}
          />
          <div className="text-xs font-normal leading-100 counter-gray-color text-right">
            {remainingCharsComment}/2000
          </div>
        </Form.Item>
        <Form.Item className="text-right">
          <ButtonCustom
            className="mr-2"
            size="large"
            type="default"
            ghost
            text="Отменить"
            onClick={onDrawerClose}
          />
          <ButtonCustom
            size="large"
            type="primary"
            text="Подтвердить"
            htmlType="submit"
            loading={isAutomaticProcessingActive}
            disabled={isAutomaticProcessingActive || !competenceGroupsVariant?.length}
          />
        </Form.Item>
      </Form>
    </>
  );
};

export default ReassignTask;
