import React, {
  FC,
  JSX,
  useEffect,
  useState
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import { AppDispatch } from "../../store/store";
import { AppStateType } from "../../reducers/mainReducer";
import {
  AttributesTypeProps,
  DocumentClientProfileType,
  FilterTasksType,
  NumberOfNotificationsType,
  ResponsibleFilterType,
  TasksListingDataType,
  UserListingType,
  UsersListingFilterType
} from "app/types";
import {
  fetchDocumentTask,
  getDocumentTaskFailure,
  getTaskPageNumber,
  getTaskPageSize,
  getTasksData,
  setTaskListingFilter,
  setTaskListingParams
} from "../../actions/tasks.actions";
import { ButtonCustom } from "../ui-kit/ButtonCustom/ButtonCustom";
import TasksListing from "./TasksListing/TasksListing";
import {
  Button,
  Checkbox,
  DatePicker,
  Drawer,
  Dropdown,
  Form,
  FormInstance,
  Input,
  notification,
  Select,
  Tabs,
  TabsProps,
  Tag,
  Tooltip
} from "antd";
import type { ColumnsType } from "antd/es/table";
import css from "./Tasks.module.css";
import { ReactComponent as SearchIcon } from "../../assets/icons/search_icon.svg";
import { ReactComponent as SettingsIcon } from "../../assets/icons/settings-icon.svg";
import { ReactComponent as SettingsDisableIcon } from "../../assets/icons/settings-icon-disable.svg";
import { ReactComponent as ErrorUpdate } from "../../assets/icons/status_error.svg";
import { RenderTaskCardDeadline } from "../../utils/RenderTaskCardDeadline";
import { GotoTaskCard } from "../../utils/GotoTaskCard";
import { RenderTaskCardClientType } from "../../utils/RenderTaskCardClientType";
import LoadingCustom from "../ui-kit/LoadingCustom/LoadingCustom";
import { RenderTaskResponsibleReference } from "../../utils/RenderTaskResponsibleReference";
import { decodeToken, getUsersListingData } from "../../actions/account.actions";
import dayjs, { Dayjs } from "dayjs";
import { renderFullDate } from "../../utils/renderDate";
import { ShortFormatTooltipText } from "../../utils/ShortFormatTooltipText";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import ChangeResponsibleModal from "../../utils/ChangeResponsibleModal/ChangeResponsibleModal";
import { useTableSorter } from "../../utils/handleSorterTable";
import { fetchDocumentClientProfile } from "../../actions/clientProfile.actions";
import ZeroSearch from "../ClientsList/ZeroSearch/ZeroSearch";
import { roleResolver } from "../../utils/roleResolver";
import { filterResponsible } from "../../utils/firlterResponsible";
import { statusBellIcon } from "../../utils/statusBellIcon";
import { priorityIcon } from "../../utils/priorityIcon";
import { updateTaskNotification } from "../../api/tasks.api";
import { FormPropsType, TasksListingType } from "./TasksType/TasksListingType/TasksListingType";
import { getNotificationsNumber } from "../../actions/notifications.actions";
import { statusIcon } from "../../utils/statusIcon";
import { deleteNoneFilter, filterParamsToArray } from "../../utils/filterParamsToArray";
import TypeFilters from "./FilterTasksItem/FiltersItem/TypeFilters";
import StatusFilters from "./FilterTasksItem/FiltersItem/StatusFilters";
import TaxSystemFilters from "./FilterTasksItem/FiltersItem/TaxSystemFilters";
import ClientsTypeFilters from "./FilterTasksItem/FiltersItem/ClientsTypeFilters";
import ChangeTaskPriorityModal from "./utils/ChangeTaskPriorityModal/ChangeTaskPriorityModal";
import { handleDateChange, innMaskChange, handleInitialValues } from "./taskItems";
import HasUpdatesFilters from "./FilterTasksItem/FiltersItem/HasUpdatesFilters";
import { taskFastFilterVariant, taskNotificationsKey } from "../../utils/catalog";
import ExportList from "../../utils/ExportList/ExportList";
import { declarationLabel } from "./TaskCards/TaskCardInfo/utils/declarationLabel";
import { declarationTopic } from "../ClientsList/ClientCard/ClientCardItem/Tasks/contactingToClient";

interface ITasksListingProps {
}

enum ListingPage {
  firstPage = 1,
  pagePerScroll = 50,
}

enum ListingTabs {
  allTaskTab = "1",
}

enum TaskActions {
  hasNoActions = 0,
}

enum ResponsibleListing {
  withSpaces = 2,
  maxLength = 35,
}

const maxVisibleTextLength: number = 50;

export const openNotificationsUpdateError = (): void => {
  const renderNotificationsUpdateError: JSX.Element = (
    <div className={css.notificationError}>
      <ErrorUpdate className="flex-shrink-0 w-6 h-6 mr-3"/>
      <div>
        <div className={css.notificationsUpdate}>
          Ошибка при обновлении статуса прочтения
        </div>
        <div className={css.notificationsUpdateText}>
          Мы не смогли обновить статус прочтения последних обновлений по задаче.
          Задача по-прежнему будет отображаться как имеющая новые обновления.
          Попробуйте снова позже.
        </div>
      </div>
    </div>
  );

  notification.open({
    message: null,
    description: renderNotificationsUpdateError,
  });
};

const Tasks: FC<ITasksListingProps> = (): JSX.Element => {
  const navigate: NavigateFunction = useNavigate();
  const [form] = Form.useForm();
  const values: FormPropsType = Form.useWatch([], form);
  const {
    typeOPF,
    taxTask,
    responsible,
    dateFrom,
    dateTo,
    deadline,
    dateContract,
    typeTask,
    statusTask,
    priority,
    hasUpdate,
    inn
  } = values || {};
  const { Option } = Select;
  const { RangePicker } = DatePicker;

  const dispatch = useDispatch<AppDispatch>();

  //получение массива задач и данные пагинации.
  const {
    tasksData,
    tasksPageSize,
    tasksPage,
    totalDocs,
    tasksSorter,
    tasksFilter,
    tasksTagKey,
    tasksParams,
    documentTask
  } = useSelector((state: AppStateType) => state.tasks);

  const {
    authToken,
    decodedToken,
    usersListing,
  } = useSelector((state: AppStateType) => state.account);

  const documentClientProfile: DocumentClientProfileType | null =
    useSelector((state: AppStateType) => state.clientProfile.documentClientProfile);

  const notificationsNumber: NumberOfNotificationsType | null =
    useSelector((state: AppStateType) => state.notifications.notificationsNumber);

  const tasksListingNotifications: NumberOfNotificationsType = Object.fromEntries(
    Object.entries(notificationsNumber ?? {}).filter(
      ([, value]): boolean => value.facet_type === "notifications_headers"
    )
  );

  useEffect((): void => {
    dispatch(decodeToken(authToken));
  }, []);

  const renderTaskType = (_: string, record: TasksListingDataType) => {
    const {
      uuid,
      task_type,
      topic,
      support_topic,
      declaration_type,
    } = record?.fields || {};

    const isDeclarationType: boolean = task_type?.[0]?.label === "Декларация";
    const shortTooltipText: string = isDeclarationType
      ? declarationLabel(declaration_type, declarationTopic)
      : support_topic ?? topic;
    
    return (
      <div className="flex flex-col">
        <span className="text-xs">
          {task_type?.[0]?.label}
        </span>
        <Link
          className={css.tableCell}
          to={`/task/${uuid}`}
        >
          <ShortFormatTooltipText
            text={shortTooltipText}
            endSlicePoint={maxVisibleTextLength}
          />
        </Link>
      </div>
    );
  };

  const [isListingLoading, setListingLoading] = useState<boolean>(false);
  //модальное окно
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [openChangeTaskPriority, setOpenChangeTaskPriority] = useState<boolean>(false);
  const [priorityTaskData, setPriorityTaskData] = useState<TasksListingDataType | null>(null);
  
  const {
    isAdmin,
    isTechSupport,
    isQualityControl,
    isAccountant,
    isAssistant,
    isContactCenter
  } = roleResolver(decodedToken);

  //логика модального окна
  const [filterData, setFilterData] = useState<FilterTasksType | null>(tasksFilter);
  const [paramsData, setParamsData] = useState<FilterTasksType | null>(tasksParams
    ? tasksParams
    : {
    page_size: tasksPageSize,
    page: tasksPage,
    order_by: tasksSorter,
  });
  const [selectedResponsible, setSelectedResponsible] = useState<ResponsibleFilterType[]>([]);
  const [isChangeResponsibleModalOpen, setChangeResponsibleModalOpen] = useState<boolean>(false);
  const [sortValue, setSortValue] = useState<string | null>("-number");
  const [isLoadingData, setLoadingData] = useState<boolean>(false);
  const [taskResponsibleUuid, setTaskResponsibleUuid] = useState<string | null>(null);
  const [filterStatusLabel, setFilterStatusLabel] = useState<string[]>([]);

  const [activeKey, setActiveKey] = useState<string>(tasksTagKey);
  const [prevActiveKey, setPrevActiveKey] = useState<string | null>(ListingTabs.allTaskTab);
  
  //логика фильтрации по типу задач, статусу, налогам, опф, приоритетам и обновлению
  const [selectedOPFType, setSelectedOPFType] = useState<string[]>(
    tasksParams?.["clients_lookup.short_name"]
    ? filterParamsToArray(tasksParams?.["clients_lookup.short_name"] as string)
    : []
  );
  const [selectedTaxSystem, setSelectedTaxSystem] = useState<string[]>(
    tasksParams?.["clients_lookup.tax_system"]
    ? filterParamsToArray(tasksParams?.["clients_lookup.tax_system"] as string)
    : []
  );
  const [selectedTaskTypes, setSelectedTaskTypes] = useState<string[]>(
    tasksParams?.task_type_lookup && activeKey === ListingTabs.allTaskTab
    ? filterParamsToArray(tasksParams.task_type_lookup as string)
    : []
  );
  const [selectedTaskStatuses, setSelectedTaskStatuses] = useState<string[]>(
    tasksParams?.status_task
    ? deleteNoneFilter(filterParamsToArray(tasksParams?.status_task as string))
    : []
  );
  const [activeStatus, setActiveStatus] = useState<string[]>(
    tasksParams?.status_task
    ? deleteNoneFilter(filterParamsToArray(tasksParams?.status_task as string))
    : []
  );
  const [updatePriorityTask, setUpdatePriorityTask] = useState<boolean>(false);
  
  const userProfileUuid: string = decodedToken?.sub ?? "";
  
  const handleSelectChange = (values: string[]): void => {
    const newSelectedResponsible: ResponsibleFilterType[] = values.map((value: string) => JSON.parse(value));
    setSelectedResponsible(newSelectedResponsible);
  };
  const filterOption = (inputValue: string, option: any): boolean => {
    const value = JSON.parse(option.value);
    const user: UserListingType | undefined =
      usersListing?.find((user: UserListingType): boolean => user.id.toString() === value.id);

    if (user) {
      const fullName: string =
        `${user?.attributes?.last_name} ${user?.attributes?.first_name} ${user?.attributes?.second_name}`;
      return fullName.toLowerCase().includes(inputValue.toLowerCase());
    }

    return false;
  };

  const placeholderSelect = (text: string): JSX.Element => {
    return (
      <div className="flex justify-between items-center">
        <div>
          {text}
        </div>
        <SearchIcon className={css.searchIcon}/>
      </div>
    );
  };

  // логика пагинации страницы
  const [currentPage, setCurrentPage] = useState<number>(tasksPage);
  const [pageSize, setPageSize] = useState<number>(tasksPageSize);

  const handlePageChange = (newPage: number): void => {
    setCurrentPage(newPage);
    dispatch(getTaskPageNumber(newPage));
    setParamsData({
      ...paramsData,
      page: newPage,
    });
  };

  const handlePageSizeChange = (newPageSize: number): void => {
    const pageSize: number = newPageSize > 20 ? ListingPage.pagePerScroll : newPageSize;

    setPageSize(pageSize);
    dispatch(getTaskPageSize(pageSize));
    setCurrentPage(ListingPage.firstPage);
    dispatch(getTaskPageNumber(ListingPage.firstPage));
    setParamsData({
      ...paramsData,
      page: ListingPage.firstPage,
      page_size: pageSize,
    });
  };

  useEffect(() => {
    setPageSize(tasksPageSize);
    setCurrentPage(tasksPage);
  }, []);

  const showModal = (): void => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    setLoadingData(true);
    dispatch(getTasksData(paramsData))
      .finally(() => {
        setLoadingData(false);
        setListingLoading(true);
      });
    dispatch(setTaskListingParams(paramsData));
  }, [paramsData, updatePriorityTask]);

  useEffect(() => {
    //сброс данных карточки задачи
    dispatch(getDocumentTaskFailure());
  }, []);

  useEffect(() => {
    if (isModalOpen) {
      const params: UsersListingFilterType = {
        page_size: 100,
      };

      dispatch(getUsersListingData(params));
    }
  }, [isModalOpen]);

  const [createdStartDate, setCreatedStartDate] = useState<string | null>(tasksParams?.created_at_gte
    ? dayjs(tasksParams.created_at_gte).format("YYYY-MM-DD")
    : null
  );
  const [createdEndDate, setCreatedEndDate] = useState<string | null>(tasksParams?.created_at_lte
    ? dayjs(tasksParams.created_at_lte).format("YYYY-MM-DD")
    : null
  );

  const [deadlineStartDate, setDeadlineStartDate] = useState<string | null>(tasksParams?.updated_at_gte
    ? dayjs(tasksParams.updated_at_gte).format("YYYY-MM-DD")
    : null
  );
  const [deadlineEndDate, setDeadlineEndDate] = useState<string | null>(tasksParams?.updated_at_lte
    ? dayjs(tasksParams.updated_at_lte).format("YYYY-MM-DD")
    : null
  );

  const [initiationStartDate, setInitiationStartDate] = useState<string | null>(
    tasksParams?.initiation_date_gte
      ? dayjs(tasksParams.initiation_date_gte).format("YYYY-MM-DD")
      : null
  );
  const [initiationEndDate, setInitiationEndDate] = useState<string | null>(
    tasksParams?.initiation_date_lte
      ? dayjs(tasksParams.initiation_date_lte).format("YYYY-MM-DD")
      : null
  );
  const [selectedUpdateTask, setSelectedUpdateTask] = useState<string[]>(tasksParams?.new_notifications
    ? [tasksParams?.new_notifications as string]
    : []
  );
  const [expiredTask, setExpiredTask] = useState<boolean | null>(null);
  
  useEffect(() => {
    if (isModalOpen) {
      const initialValues: FormPropsType = handleInitialValues(userProfileUuid, tasksParams, usersListing);
      const responsibleData: ResponsibleFilterType[] | undefined = initialValues?.responsible
        ? initialValues.responsible.map((responsible: string): ResponsibleFilterType => JSON.parse(responsible))
        : [];
      const filteredStatuses: string[] = initialValues.statusTask
        ? initialValues.statusTask
          .filter((status: string) =>
          !taskFastFilterVariant.includes(status)
           && !status.includes("!")
        )
        : [];
      
      form.setFieldsValue(initialValues);
      setCreatedStartDate(initialValues?.dateFrom?.[0].format("YYYY-MM-DD") ?? null);
      setCreatedEndDate(initialValues?.dateFrom?.[1].format("YYYY-MM-DD") ?? null);
      setDeadlineStartDate(initialValues?.dateTo?.[0].format("YYYY-MM-DD") ?? null);
      setDeadlineEndDate(initialValues?.dateTo?.[1].format("YYYY-MM-DD") ?? null);
      setInitiationStartDate(initialValues?.dateContract?.[0].format("YYYY-MM-DD") ?? null);
      setInitiationEndDate(initialValues?.dateContract?.[1].format("YYYY-MM-DD") ?? null);
      setSelectedResponsible(responsibleData as ResponsibleFilterType[]);
      setSelectedTaskStatuses(filteredStatuses);
      setSelectedOPFType(initialValues.typeOPF ?? []);
      setSelectedTaxSystem(initialValues.taxTask ?? []);
      setExpiredTask(initialValues.deadline ?? null);
      setSelectedUpdateTask(initialValues.hasUpdate ?? []);
      
      if (activeKey === ListingTabs.allTaskTab) {
        setSelectedTaskTypes(initialValues.typeTask ?? []);
      }
    }
  }, [isModalOpen]);
  
  const onCheckboxExpiredTask = (e: CheckboxChangeEvent): void => {
    const { name, checked } = e.target;
    setExpiredTask(name === "yes" ? checked : !checked);
  };
  
  const taskTypeData = (activeKey: string): string | null => {
    const selectedType: string | null = selectedTaskTypes?.length ? `[${selectedTaskTypes}]` : null;
    
    switch (activeKey) {
      case "2":
        return "[301]";
      case "3":
        return "[302]";
      case "4":
        return "[100-300]";
      default:
        return selectedType;
    }
  };
  
  const getStatuses = (hasString: boolean): string[] | string | null | undefined => {
    const activeStatusData: string[] | undefined =
      activeStatus?.filter((status: string): boolean => taskFastFilterVariant.includes(status));
    const taskStatuses: string[] = [...new Set([...selectedTaskStatuses, ...activeStatusData])];
    const filterStatus: string[] = taskStatuses.filter((status: string): boolean =>
      !taskFastFilterVariant.includes(status));
    
    if (!(selectedTaskStatuses.length || activeStatus.length)) {
      return null;
    }
    
    return hasString ? `[${taskStatuses}]` : filterStatus;
  };

  const modalOk = (): void => {
    setCurrentPage(ListingPage.firstPage);
    const hasUserUuid: boolean =
      filterParamsToArray(tasksParams?.responsible_lookup as string).includes(userProfileUuid);
    const uuidResponsible: string[] = selectedResponsible.map((uuid: ResponsibleFilterType) => uuid.id);
    const uuidAllResponsible: string[] = hasUserUuid ? [...uuidResponsible, userProfileUuid] : uuidResponsible;
    
    const hasNonStatus: boolean = !!tasksParams?.status_task?.includes("!")
      && !selectedTaskStatuses.length
      && !activeStatus.length;
    const statusTask: string[] | string | null | undefined = hasNonStatus
      ? tasksParams?.status_task
      : getStatuses(true);
    const innData: string | null = inn ? inn.replace(/\s+/g, "") : null;
    
    setParamsData((prev: FilterTasksType | null) => {
      const newState: FilterTasksType = {
        ...prev,
        clients: tasksParams?.clients || null,
        "clients_lookup.short_name": selectedOPFType?.length ? `[${selectedOPFType}]` : null,
        status_task: statusTask,
        task_type_lookup: taskTypeData(activeKey),
        page_size: tasksPageSize,
        page: ListingPage.firstPage,
        "clients_lookup.tax_system": selectedTaxSystem?.length ? `[${selectedTaxSystem}]` : null,
        responsible_lookup: selectedResponsible.length ? `[${uuidAllResponsible}]` : null,
        inn: innData,
        created_at_gte: createdStartDate ? createdStartDate.slice(0, 10) : null,
        created_at_lte: createdEndDate ? createdEndDate.slice(0, 10) : null,
        order_by: paramsData?.order_by,
        is_expired: expiredTask ? "True" : null,
        updated_at_gte: deadlineStartDate ? deadlineStartDate.slice(0, 10) : null,
        updated_at_lte: deadlineEndDate ? deadlineEndDate.slice(0, 10) : null,
        initiation_date_gte: initiationStartDate ? initiationStartDate.slice(0, 10) : null,
        initiation_date_lte: initiationEndDate ? initiationEndDate.slice(0, 10) : null,
        new_notifications: selectedUpdateTask?.length ? selectedUpdateTask?.[0] : null,
      };
      
      if (prev?.deadline_gte != null && (prev?.is_expired != null || expiredTask)) {
        newState.or_filters = "[deadline, is_expired]";
      }

      if (activeKey === ListingTabs.allTaskTab && deadlineStartDate && deadlineEndDate) {
        const prevStatus: string[] = filterParamsToArray(prev?.status_task as string);
        const newStatusTask: string[] =
          [...new Set([...prevStatus, ...selectedTaskStatuses, "FIN_TRUE", "FIN_FALSE"])];
        
        newState.status_task = `[${newStatusTask}]`;
        setActiveStatus((prev: string[]) => {
          return [...new Set([...prev, "FIN_TRUE", "FIN_FALSE"])];
        });
      }

      dispatch(setTaskListingParams(newState));
      return newState;
    });

    setFilterData(() => {
      const newState: FilterTasksType = {
        "clients_lookup.short_name": selectedOPFType?.length ? selectedOPFType : null,
        "clients_lookup.tax_system": selectedTaxSystem?.length ? selectedTaxSystem : null,
        status_task: getStatuses(false),
        task_type_lookup: selectedTaskTypes?.length ? selectedTaskTypes : null,
        responsible_lookup: selectedResponsible.length ? selectedResponsible : null,
        is_expired: expiredTask ? "Срок задачи истек" : null,
        inn: innData,
        created_at_gte: (createdStartDate && createdEndDate)
          ? renderFullDate(createdStartDate, createdEndDate)
          : null,
        updated_at_lte: (deadlineStartDate && deadlineEndDate)
          ? renderFullDate(deadlineStartDate, deadlineEndDate)
          : null,
        initiation_date_gte: (initiationStartDate && initiationEndDate)
          ? renderFullDate(initiationStartDate, initiationEndDate)
          : null,
        new_notifications: selectedUpdateTask?.length
          ? taskNotificationsKey[selectedUpdateTask[0]]
          : null,
      };

      dispatch(setTaskListingFilter(newState));
      return newState;
    });

    setIsModalOpen(false);
    setCurrentPage(ListingPage.firstPage);
  };
  
  const defaultValues = (): void => {
    setCreatedStartDate(null);
    setCreatedEndDate(null);
    setDeadlineStartDate(null);
    setDeadlineEndDate(null);
    setInitiationStartDate(null);
    setInitiationEndDate(null);
    setSelectedResponsible([]);
    setSelectedTaskStatuses([]);
    setSelectedTaskTypes([]);
    setSelectedOPFType([]);
    setSelectedTaxSystem([]);
    setExpiredTask(null);
    setSelectedUpdateTask([]);
  };
  
  const clearFilter = (): void => {
    form.resetFields();
    defaultValues();
  };
  
  const clearSideFilters = (): void => {
    form.resetFields([
      "typeOPF",
      "taxTask",
      "statusTask",
      "typeTask",
      "inn",
      "responsible",
      "dateFrom",
      "dateTo",
      "dateContract",
      "deadline",
      "hasUpdate"
    ] as any);
    defaultValues();
  };

  const modalCancel = (): void => {
    setIsModalOpen(false);
    clearSideFilters();
  };

  const buttonAccess = (): boolean => {
    const dataValues: FormPropsType = {
      typeOPF,
      taxTask,
      responsible,
      dateFrom,
      dateTo,
      deadline,
      dateContract,
      typeTask,
      statusTask,
      priority,
      hasUpdate,
      inn
    };

    return Object.values(dataValues).some(value =>
      value !== null &&
      value !== undefined &&
      value !== false &&
      !(Array.isArray(value) && value.length === 0)
    );
  };

  const modalFooter: JSX.Element = (
    <div className={css.modalFooter}>
      <div key="modalBotton" className={css.modalFooterButton}>
        <ButtonCustom
          id="modalBottonCancel"
          className={css.buttonStyle}
          key="cancel"
          size="large"
          type="default"
          text="Сбросить все"
          onClick={clearSideFilters}
        />
        <ButtonCustom
          id="modalBottonOk"
          className={css.buttonStyle}
          key="ok"
          size="large"
          text="Применить"
          type="primary"
          onClick={modalOk}
          disabled={!buttonAccess()}
        />
      </div>
    </div>
  );

  //Логика сортировки таблицы
  const [handleSorter] = useTableSorter("-number", setParamsData, setSortValue);

  const tableTitle = (text: string): JSX.Element => {
    return (
      <div className="table-title">
        {text}
      </div>
    );
  };

  const fetchNotification = async (taskData: TasksListingDataType): Promise<void> => {
    const uuidTask: string | undefined = taskData?.fields?.uuid;
    const isNotificationRead: boolean = !!taskData?.notification?.viewed_at;

    try {
      const resolve = await updateTaskNotification({task_uuid: uuidTask, read: !isNotificationRead});

      if (resolve.status === 200) {
        dispatch(getTasksData(paramsData));
        dispatch(getNotificationsNumber());
      }
    } catch (error) {
      openNotificationsUpdateError();
    }
  };
  
  const openChangeTaskPriorityModal = (taskData: TasksListingDataType): void => {
    setOpenChangeTaskPriority(true);
    setPriorityTaskData(taskData);
  };

  const tableHeaderCell = (sortKey: string) => (
  ) => ({ onClick: (e: React.MouseEvent<HTMLElement>) => handleSorter(e, sortKey), });
  
  //TODO как будет готов бэк, подкорректировать данные таблицы
  //шапка таблицы
  const columns: ColumnsType<TasksListingDataType> = [
    {
      title: tableTitle("№"),
      sorter: true,
      showSorterTooltip: false,
      dataIndex: ["fields", "number"],
      width: "5%",
      key: "№",
      align: "center",
      className: css.tableHeader,
      onHeaderCell: tableHeaderCell("number"),
      render: (_: string, record: TasksListingDataType) => GotoTaskCard(record),
    },
    {
      sorter: true,
      showSorterTooltip: false,
      width: "1%",
      key: "priority",
      align: "center",
      className: css.tableHeader,
      onHeaderCell: tableHeaderCell("priority"),
      render: (_: string, record: TasksListingDataType) => priorityIcon(record),
    },
    {
      //TODO как будет готова сортировка на бэке дописать логику, sorter выставить true
      sorter: false,
      showSorterTooltip: false,
      width: "1%",
      key: "notification",
      align: "center",
      className: css.tableHeader,
      // onHeaderCell: tableHeaderCell(""),
      render: (_: string, record: TasksListingDataType) => statusBellIcon(record),
    },
    {
      title: tableTitle("Срок"),
      sorter: true,
      showSorterTooltip: false,
      dataIndex: ["fields", "deadline"],
      width: "10%",
      key: "Срок",
      className: css.tableHeader,
      onHeaderCell: tableHeaderCell("deadline"),
      render: (text: string, record: TasksListingDataType) =>
       text ? RenderTaskCardDeadline(text, record) : "",
    },
    {
      title: tableTitle("Задача"),
      sorter: true,
      showSorterTooltip: false,
      dataIndex: ["fields", "task_type"],
      width: "38%",
      key: "Тип задач",
      className: css.tableHeader,
      onHeaderCell: tableHeaderCell("task_type.label"),
      render: renderTaskType,
    },
    {
      title: tableTitle("Клиент"),
      sorter: true,
      showSorterTooltip: false,
      dataIndex: "fields",
      width: "22%",
      key: "клиент",
      className: css.tableHeader,
      onHeaderCell: tableHeaderCell("client_profile.label"),
      render: (_: string, record: TasksListingDataType) => RenderTaskCardClientType(_, record, navigate),
    },
    {
      title: tableTitle("Статус"),
      sorter: true,
      showSorterTooltip: false,
      dataIndex: ["fields", "status_task"],
      width: "11%",
      key: "Статус",
      className: css.tableHeader,
      onHeaderCell: tableHeaderCell("status_task"),
      render: (text: string, record: TasksListingDataType) => statusIcon(text, record),
    },
    {
      title: tableTitle("Ответственный"),
      sorter: true,
      showSorterTooltip: false,
      dataIndex: ["fields", "responsible_reference"],
      width: "11%",
      key: "Ответственный сотрудник",
      className: css.tableHeader,
      onHeaderCell: tableHeaderCell("responsible_reference.label"),
      render: (text: string, record: TasksListingDataType) =>
       RenderTaskResponsibleReference(text, record),
    },
    {
      title: "",
      key: "settings",
      className: css.tableHeader,
      render: (_, record: TasksListingDataType) => {
        type ItemsPropsType = {
          key: string,
          label: string,
          onClick: () => void,
        };

        const isFulfilledTask: boolean = record?.fields?.status_task === "FIN_TRUE"
          || record?.fields?.status_task === "CLOSED_BY_FNS_SUCCESS";
        const isRejectedTask: boolean = record?.fields?.status_task === "FIN_FALSE";
        const isResponsibleForTask: boolean = record?.fields?.responsible_reference?.[0]?.uuid === decodedToken?.sub;
        const hasNotificationData: boolean = !!record?.notification;
        const isNotificationRead: boolean = !!record?.notification?.viewed_at;
        const isHighPriority: boolean = record?.fields?.priority === "Высокий";
        const hasActionButton: boolean = (isResponsibleForTask || isTechSupport) && hasNotificationData;
        const isAccessChangeResponsible: boolean = isAdmin || isTechSupport;
        const isAccessChangePriority: boolean = !(isAccountant || isAssistant);

        const updateTask: ItemsPropsType = {
          key: "2",
          label: isNotificationRead ? "Отметить не прочитанным" : "Отметить прочитанным",
          onClick: () => fetchNotification(record)
        };

        const changeResponsible: ItemsPropsType = {
          key: "1",
          label: "Сменить ответственного",
          onClick: () => onItemClick(record)
        };
        
        const updatePriority: ItemsPropsType = {
          key: "3",
          label: isHighPriority ? "Понизить приоритет" : "Повысить приоритет",
          onClick: () => openChangeTaskPriorityModal(record)
        };

        const items: ItemsPropsType[] = [
          ...(isAccessChangeResponsible ? [changeResponsible] : []),
          ...(hasActionButton ? [updateTask] : []),
          ...(isAccessChangePriority ? [updatePriority] : [])
        ];
        const hasNoActions: boolean = items?.length === TaskActions.hasNoActions;
        const isHideSettings: boolean = isFulfilledTask || isRejectedTask;
        const isDisableButton: boolean = isFulfilledTask || isRejectedTask || hasNoActions;
        const isAvailable: boolean = isAdmin || isTechSupport || isContactCenter || isResponsibleForTask;
        
        return (isAvailable && (
          <Dropdown.Button
            menu={isHideSettings ? { items: [] } : { items }}
            buttonsRender={([_leftButton, _rightButton]) => [
              null,
              <Button
                className={isDisableButton ? css.settingsBtnDisable : css.settingsBtn}
                disabled={isDisableButton}
                key={record?.fields?.uuid}
                type="text"
                icon={isDisableButton ? <SettingsDisableIcon /> : <SettingsIcon />}
              />,
            ]}
          />
        ));
      }
    },
  ];

  const onItemClick = (taskData: TasksListingDataType): void => {
    dispatch(fetchDocumentTask(taskData?.fields?.uuid));
    setChangeResponsibleModalOpen(true);
    setTaskResponsibleUuid(taskData?.fields?.responsible_reference?.[0]?.uuid
      ?? taskData?.fields?.temporary_responsible_reference?.[0]?.uuid);

    if (taskData?.fields?.client_profile_ooo?.length) {
      dispatch(fetchDocumentClientProfile(
        "client-profile-ooo",
        taskData.fields.client_profile_ooo[0].uuid
      ));
    }

    if (taskData?.fields?.client_profile_ip?.length) {
      dispatch(fetchDocumentClientProfile(
        "client-profile-ip",
        taskData.fields.client_profile_ip[0].uuid
      ));
    }
  };

  const [tabChange, setTabChange] = useState<boolean>(false);

  const TasksListingComponent = (form: FormInstance): JSX.Element => {
    const tasksListingProps: TasksListingType = {
      activeKey,
      setActiveKey,
      prevActiveKey,
      tabChange,
      columns,
      filterData,
      currentPage,
      pageSize,
      setFilterData,
      paramsData,
      setParamsData,
      setCurrentPage,
      showModal,
      handlePageChange,
      handlePageSizeChange,
      sortValue,
      isLoadingData,
      setFilterStatusLabel,
      selectedTaskStatuses,
      setSelectedTaskStatuses,
      setSelectedOPFType,
      setSelectedTaxSystem,
      setSelectedTaskTypes,
      setCreatedStartDate,
      setCreatedEndDate,
      setDeadlineStartDate,
      setDeadlineEndDate,
      setInitiationStartDate,
      setInitiationEndDate,
      activeStatus,
      setActiveStatus,
      clearFilter
    };

    return (
      <TasksListing
        form={form}
        tasksListingProps={tasksListingProps}
        setLoadingData={setLoadingData}
      />
    );
  };

  const renderTabLabel = (label: string, key: string): JSX.Element => {
    const notificationVariant: NumberOfNotificationsType = Object.fromEntries(
      Object.entries(tasksListingNotifications).filter(
        ([, value]): boolean => value?.label === label
      )
    );
    const notificationAmountValue: number = Object.values(notificationVariant)?.[0]?.notifications_amount;
    const isNotificationAmount: boolean = notificationAmountValue > 0;

    return (
      <div className="flex items-center">
        <div className="mr-1">{label}</div>
        {isNotificationAmount && (
          <div
            className={css.counterLabel}
            style={activeKey === key ? { backgroundColor: "#EDF1FF" } : { backgroundColor: "#F0F1F3" }}
          >
            {notificationAmountValue ?? ""}
          </div>
        )}
      </div>
    );
  };

  //данные верхнего меню страницы
  const itemsTabs: TabsProps["items"] = [
    {
      key: "1",
      label: renderTabLabel("Все", "1"),
    },
    {
      key: "2",
      label: renderTabLabel("Онбординг", "2"),
    },
    {
      key: "3",
      label: renderTabLabel("Обращения", "3"),
    },
    {
      key: "4",
      label: renderTabLabel("Исходящие", "4"),
    },
    {
      key: "5",
      label: renderTabLabel("Разметка операций", "5"),
    },
  ];

  const modalTitle: JSX.Element = (
    <div className={css.modalHeader}>
      Фильтры
    </div>
  );

  const handleTabChange = (key: string): void => {
    setTabChange(true);
    setPrevActiveKey(activeKey);
    setActiveKey(key);
  };

  const responsibleTagRender = (props: any): JSX.Element => {
    interface IDateType {
      id: string;
      name: string;
    }

    const { value, closable, onClose } = props;
    let data: IDateType | null = null;
    try {
      if (typeof value === "string" && value.trim().startsWith("{") && value.trim().endsWith("}")) {
        data = JSON.parse(value);
      }
    } catch (error) {
      console.error("Error parsing JSON value:", value, error);
    }

    return (
      <Tag
        className={css.blockResponsibleTag}
        closable={closable}
        onClose={onClose}
      >
        <div>{data?.name}</div>
      </Tag>
    );
  };

  const renderCompletionDate: boolean = filterStatusLabel.includes("Исполнены")
    || filterStatusLabel.includes("Не исполнены")
    || filterStatusLabel.length === 0;
  const renderWorkDate: boolean =
    filterStatusLabel.includes("В работе") ||
    filterStatusLabel.includes("Исполнены") ||
    filterStatusLabel.includes("Не исполнены") ||
    filterStatusLabel.length === 0;
  const renderStatusFilter: boolean = activeKey === ListingTabs.allTaskTab;
  
  const handleInnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    form.setFieldValue("inn" as any, innMaskChange(event.target.value));
  };
  
  const availableDataFrom = (current: Dayjs): boolean => {
    if (current.isAfter(dayjs(), "day")) {
      return true;
    }
    
    if (deadlineStartDate && current.isAfter(dayjs(deadlineStartDate), "day")) {
      return true;
    }
    
    return !!(initiationStartDate && current.isAfter(dayjs(initiationStartDate), "day"));
  };
  
  const availableDataTo = (current: Dayjs): boolean => {
    if (createdEndDate && current.isBefore(dayjs(createdEndDate), "day")) {
      return true;
    }
    
    return !!(initiationStartDate && current.isBefore(dayjs(initiationStartDate), "day"));
  };
  
  const availableDateContract = (current: Dayjs): boolean => {
    if (current.isAfter(dayjs(), "day")) {
      return true;
    }
    
    if (createdStartDate && current.isBefore(dayjs(createdStartDate), "day")) {
      return true;
    }
    
    return !!(deadlineEndDate && current.isAfter(dayjs(deadlineEndDate), "day"));
  };
  
  const renderOption = (id: string, attributes: AttributesTypeProps): JSX.Element => {
    const { position, last_name = "", first_name = "", second_name = "" } = attributes;
    const nameLength: number =
      last_name?.[0]?.length +
      first_name?.[0]?.length +
      second_name?.[0]?.length +
      ResponsibleListing.withSpaces;
    const isLongName: boolean = nameLength > ResponsibleListing.maxLength;
    const value: string = JSON.stringify({
      id: id.toString(),
      name: `${last_name} ${first_name} ${second_name}`,
    });
    
    return (
      <Option
        className={css.responsible}
        key={id}
        value={value}
      >
        <div>
          <div className={css.blockPosition}>{position}</div>
          <div className={css.blockResponsible}>
            {isLongName ? (
              <Tooltip title={`${last_name} ${first_name} ${second_name}`} placement="topLeft">
                <div>{`${last_name} ${first_name} ${second_name}`}</div>
              </Tooltip>
              ) : (
              <div>{`${last_name} ${first_name} ${second_name}`}</div>
            )}
          </div>
        </div>
      </Option>
    );
  };

  return (
    isListingLoading ?
      (
        <Form form={form}>
          <div className={css.container}>
            <div className="w-full flex justify-between items-center">
              <h1 className={css.containerHeader}>Задачи</h1>
              <ExportList variant="задачи" />
            </div>
            <Tabs
              defaultActiveKey={activeKey}
              activeKey={activeKey}
              items={itemsTabs}
              onChange={handleTabChange}
            />
            {TasksListingComponent(form)}
            <Drawer
              title={modalTitle}
              footer={modalFooter}
              onClose={modalCancel}
              open={isModalOpen}
            >
              <div className={css.modalContainer}>
                <div className="mb-5">
                  <ClientsTypeFilters
                    form={form}
                    isModalOpen={isModalOpen}
                    selectedOPFType={selectedOPFType}
                    setSelectedOPFType={setSelectedOPFType}
                  />
                </div>
                <div className="mb-5">
                  <TaxSystemFilters
                    form={form}
                    isModalOpen={isModalOpen}
                    selectedTaxSystem={selectedTaxSystem}
                    setSelectedTaxSystem={setSelectedTaxSystem}
                  />
                </div>
                <div className="mb-5">
                  <StatusFilters
                    form={form}
                    isModalOpen={isModalOpen}
                    selectedTaskStatuses={selectedTaskStatuses}
                    setSelectedTaskStatuses={setSelectedTaskStatuses}
                  />
                </div>
                {renderStatusFilter && (
                  <div className="mb-5">
                    <TypeFilters
                      form={form}
                      isModalOpen={isModalOpen}
                      selectedTaskTypes={selectedTaskTypes}
                      setSelectedTaskTypes={setSelectedTaskTypes}
                    />
                  </div>
                )}
                {/*TODO вернуть как будет готов бэк*/}
                {/*<div className="mb-5">*/}
                {/*  <PriorityFilters*/}
                {/*    form={form}*/}
                {/*    isModalOpen={isModalOpen}*/}
                {/*    selectedPriorityTask={selectedPriorityTask}*/}
                {/*    setSelectedPriorityTask={setSelectedPriorityTask}*/}
                {/*  />*/}
                {/*</div>*/}
                <div className="mb-5">
                  <HasUpdatesFilters
                    form={form}
                    isModalOpen={isModalOpen}
                    selectedUpdateTask={selectedUpdateTask}
                    setSelectedUpdateTask={setSelectedUpdateTask}
                  />
                </div>
                <div className="mb-5">
                  <div className={`${css.modalTextTask} mb-2`}>По ИНН</div>
                  <Form.Item name="inn" noStyle>
                    <Input
                      id="inn"
                      placeholder="Номер ИНН"
                      size="large"
                      onChange={handleInnChange}
                    />
                  </Form.Item>
                </div>
                {isAdmin && (
                  <div className="mb-5">
                    <div className={`${css.modalTextTask} mb-2`}>По ответственному сотруднику</div>
                    <Form.Item name="responsible" className="mt-0 mb-0">
                      <Select
                        id="responsible"
                        mode="multiple"
                        showSearch
                        className={css.selectImplementer}
                        placeholder={placeholderSelect("Выберите сотрудника")}
                        size="large"
                        suffixIcon={null}
                        onChange={handleSelectChange}
                        filterOption={filterOption}
                        notFoundContent={<ZeroSearch dataFilter />}
                        value={selectedResponsible.map((responsible: ResponsibleFilterType
                        ) => JSON.stringify(responsible))}
                        tagRender={responsibleTagRender}
                      >
                        {filterResponsible(usersListing)?.map(({ id, attributes }: UserListingType) => {
                          return renderOption(id, attributes);
                        })}
                      </Select>
                    </Form.Item>
                  </div>
                )}
                <div className="mb-5">
                  <div className={`${css.modalTextTask} mb-2`}>Дата создания</div>
                    <Form.Item name="dateFrom" className="mt-0 mb-0">
                      <RangePicker
                        id="dateFrom"
                        size="large"
                        className="w-full mb-2"
                        format="DD.MM.YYYY"
                        placeholder={["С даты", "По дату"]}
                        disabledDate={availableDataFrom}
                        onChange={(dates) => handleDateChange(dates, setCreatedStartDate, setCreatedEndDate)}
                      />
                    </Form.Item>
                  </div>
                  {renderCompletionDate && (
                    <div>
                      <div className={`${css.modalTextTask} mb-2`}>Дата завершения</div>
                        <Form.Item name="dateTo" className="mt-0 mb-0">
                          <RangePicker
                            id="dateTo"
                            size="large"
                            className="w-full mb-2"
                            format="DD.MM.YYYY"
                            placeholder={["С даты", "По дату"]}
                            disabledDate={availableDataTo}
                            onChange={(dates) => handleDateChange(dates, setDeadlineStartDate, setDeadlineEndDate)}
                          />
                        </Form.Item>
                    </div>
                  )}
                  <div className="mb-5">
                    <Form.Item
                      name="deadline"
                      valuePropName="checked"
                      className="mt-0 mb-0"
                    >
                      <Checkbox
                        name="yes"
                        onChange={onCheckboxExpiredTask}
                      >
                        Срок задачи истек
                      </Checkbox>
                    </Form.Item>
                  </div>
                  {renderWorkDate && (
                    <div className="mb-5">
                      <div className={`${css.modalTextTask} mb-2`}>Дата взятия в работу</div>
                      <Form.Item name="dateContract" className="mt-0 mb-0">
                        <RangePicker
                          id="dateContract"
                          size="large"
                          className="w-full mb-2"
                          format="DD.MM.YYYY"
                          placeholder={["С даты", "По дату"]}
                          disabledDate={availableDateContract}
                          onChange={(dates) => handleDateChange(dates, setInitiationStartDate, setInitiationEndDate)}
                        />
                      </Form.Item>
                    </div>
                  )}
              </div>
            </Drawer>
          </div>
          {isChangeResponsibleModalOpen && (
            <ChangeResponsibleModal
              docSchema="task"
              taskInfo={documentTask}
              paramsData={paramsData}
              isModalOpen={isChangeResponsibleModalOpen}
              setIsModalOpen={setChangeResponsibleModalOpen}
              clientProfileInfo={documentClientProfile}
              responsibleUuid={taskResponsibleUuid}
            />
          )}
          <ChangeTaskPriorityModal
            isModalOpen={openChangeTaskPriority}
            setIsModalOpen={setOpenChangeTaskPriority}
            taskData={priorityTaskData}
            setUpdatePriorityTask={setUpdatePriorityTask}
          />
        </Form>
      ) : (
        <div className="text-center mt-96">
          <LoadingCustom/>
        </div>
      )
  );
};

export default Tasks;
