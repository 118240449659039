import { SubstitutesType } from "app/types";

export const competenceGroups: SubstitutesType[] = [
  {
    key: "Выписки",
    value: "Выписки",
    label: "Выписки"
  },
  {
    key: "Зарплаты",
    value: "Зарплаты",
    label: "Зарплаты"
  },
  {
    key: "Маркетплейсы",
    value: "Маркетплейсы",
    label: "Маркетплейсы"
  },
  {
    key: "ОФД (онлайн кассы)",
    value: "ОФД (онлайн кассы)",
    label: "ОФД (онлайн кассы)"
  },
  {
    key: "Онбординг",
    value: "Онбординг",
    label: "Онбординг"
  },
  {
    key: "Главные бухгалтера (закрепляются за клиентами)",
    value: "Главные бухгалтера (закрепляются за клиентами)",
    label: "Главные бухгалтера (закрепляются за клиентами)"
  },
];