const DECLARATION_CATEGORY_CODE: string | undefined = process.env.REACT_APP_DECLARATION_CATEGORY_CODE;

export interface IContactingToClientProps {
  key: number,
  value: string,
  label: string,
}

export interface ITaskTypeProps {
  key?: number,
  value: string,
  label: string,
}

export const topicOfAppeal: IContactingToClientProps[] = [
  {
    key: 201,
    value: "Задача по формированию КУДиР",
    label: "Задача по формированию КУДиР",
  },
  {
    key: DECLARATION_CATEGORY_CODE ? Number(DECLARATION_CATEGORY_CODE) : 202,
    value: "Задача по формированию декларации",
    label: "Задача по формированию декларации",
  },
  {
    key: 112,
    value: "Задача по формированию уведомления об исчисленных налогах",
    label: "Задача по формированию уведомления об исчисленных налогах",
  },
  {
    key: 203,
    value: "Задача по расчету авансового платежа по сотруднику",
    label: "Задача по расчету авансового платежа по сотруднику",
  },
  {
    key: 204,
    value: "Задача по расчету заработной платы сотрудника",
    label: "Задача по расчету заработной платы сотрудника",
  },
  {
    key: 210,
    value: "Сдача ЕФС-1",
    label: "Сдача ЕФС-1",
  },
  {
    key: 205,
    value: "Задача по расчету отпускных сотруднику",
    label: "Задача по расчету отпускных сотруднику",
  },
  {
    key: 211,
    value: "Сдача СВЗ-ТД",
    label: "Сдача СВЗ-ТД",
  },
  {
    key: 208,
    value: "Задача о формировании платежа для уплаты взносов",
    label: "Задача о формировании платежа для уплаты взносов",
  },
  {
    key: 212,
    value: "Формирование заявлений на уменьшение налога",
    label: "Формирование заявлений на уменьшение налога",
  },
  {
    key: 213,
    value: "Подготовка бухгалтерской отчётности для ООО",
    label: "Подготовка бухгалтерской отчётности для ООО",
  },
  {
    key: 209,
    value: "Прочая информационная задача",
    label: "Прочая информационная задача",
  },
  {
    key: 206,
    value: "Задача по формированию больничного",
    label: "Задача по формированию больничного",
  },
  {
    key: 214,
    value: "Отправка платежных поручений",
    label: "Отправка платежных поручений",
  },
  {
    key: 110,
    value: "Задача на получение выписок по счетам Клиента",
    label: "Задача на получение выписок по счетам Клиента",
  },
  {
    key: 108,
    value: "Задача по запросу прочей информации",
    label: "Задача по запросу прочей информации",
  },
  {
    key: 101,
    value: "Задача на получение первичных документов по операциям",
    label: "Задача на получение первичных документов по операциям",
  },
  {
    key: 102,
    value: "Задача по получению документов по сотрудникам",
    label: "Задача по получению документов по сотрудникам",
  },
  {
    key: 103,
    value: "Задача по получению отчета по онлайн кассам",
    label: "Задача по получению отчета по онлайн кассам",
  },
  {
    key: 104,
    value: "Задача по получению отчета по агентским договорам",
    label: "Задача по получению отчета по агентским договорам",
  },
  {
    key: 105,
    value: `Задача по запросу от клиента подтверждения действия
     (например, уточнить, отправил ли он уведомление об исчисленных налогах)`,
    label: `Задача по запросу от клиента подтверждения действия 
    (например, уточнить, отправил ли он уведомление об исчисленных налогах)`,
  },
  {
    key: 106,
    value: "Задача по запросу графика выплат сотрудникам, если трудовой договор не включает дат",
    label: "Задача по запросу графика выплат сотрудникам, если трудовой договор не включает дат",
  },
  {
    key: 107,
    value: `Задача по сотрудникам в рамках уточнения табеля рабочего времени
     (ходили ли сотрудники на больничный, отпуск и тд)`,
    label: `Задача по сотрудникам в рамках уточнения табеля рабочего времени 
    (ходили ли сотрудники на больничный, отпуск и тд)`,
  },
  {
    key: 111,
    value: "Задача по запросу данных прошлых периодов",
    label: "Задача по запросу данных прошлых периодов",
  },
  {
    key: 403,
    value: "Списание платной консультации",
    label: "Списание платной консультации",
  },
  {
    //TODO сейчас временный key, поменять как будет согласован код заявки
    key: 998,
    value: "Заявка на повышение тарифа",
    label: "Заявка на повышение тарифа",
  },
  {
    //TODO сейчас временный key, поменять как будет согласован код заявки
    key: 999,
    value: "Заявка на прекращение обслуживания",
    label: "Заявка на прекращение обслуживания",
  },
];

export const taskType:ITaskTypeProps[] = [
  {
    value: "Запрос информации у клиента",
    label: "Запрос информации у клиента",
  },
  {
    value: "Предоставление информации клиенту",
    label: "Предоставление информации клиенту",
  },
  {
    value: "Платные услуги",
    label: "Платные услуги",
  },
];

export const appealTopic: ITaskTypeProps[] = [
  {
    value: "Вопрос к бухгалтеру",
    label: "Вопрос к бухгалтеру",
  },
  {
    value: "Налоговый вычет",
    label: "Налоговый вычет",
  },
  {
    value: "Календарь налоговых событий",
    label: "Календарь налоговых событий",
  },
  {
    value: "Вопрос по СНО",
    label: "Вопрос по СНО",
  },
  {
    value: "Уточнение статуса обработки документов",
    label: "Уточнение статуса обработки документов",
  },
  {
    value: "Не поступил расчет",
    label: "Не поступил расчет",
  },
  {
    value: "Восстановление отчетности",
    label: "Восстановление отчетности",
  },
  {
    value: "Сдача отчетности",
    label: "Сдача отчетности",
  },
  {
    value: "Вопрос по Единому налоговому платежу (ЕНП)",
    label: "Вопрос по Единому налоговому платежу (ЕНП)",
  },
  {
    value: "Вопрос по Единому налоговому счету (ЕНС)",
    label: "Вопрос по Единому налоговому счету (ЕНС)",
  },
  {
    value: "Уведомление об исчисленных налогах и взносах",
    label: "Уведомление об исчисленных налогах и взносах",
  },
  {
    value: "КУДиР",
    label: "КУДиР",
  },
  {
    value: "Декларация УСН",
    label: "Декларация УСН",
  },
  {
    value: "Декларация 3-НДФЛ",
    label: "Декларация 3-НДФЛ",
  },
  {
    value: "Расчет авансового платежа по сотруднику",
    label: "Расчет авансового платежа по сотруднику",
  },
  {
    value: "Расчет заработной платы сотрудника",
    label: "Расчет заработной платы сотрудника",
  },
  {
    value: "Расчет отпускных сотруднику",
    label: "Расчет отпускных сотруднику",
  },
  {
    value: "Платежное поручения для уплаты налогов и взносов",
    label: "Платежное поручения для уплаты налогов и взносов",
  },
  {
    value: "Отчеты по сотрудникам в налоговую  и социальный фонд России",
    label: "Отчеты по сотрудникам в налоговую  и социальный фонд России",
  },
  {
    value: "Заявление на уменьшение налога",
    label: "Заявление на уменьшение налога",
  },
  {
    value: "Декларации по имуществу ООО",
    label: "Декларации по имуществу ООО",
  },
  {
    value: "Декларация НДС",
    label: "Декларация НДС",
  },
  {
    value: "Декларация по налогу на прибыль",
    label: "Декларация по налогу на прибыль",
  },
  {
    value: "Платная консультация",
    label: "Платная консультация",
  },
];

export const declarationTopic: ITaskTypeProps[] = [
  {
    key: 309,
    value: "Формирование декларации НДС",
    label: "Формирование декларации НДС",
  },
  {
    key: 305,
    value: "Формирование декларации УСН",
    label: "Формирование декларации УСН",
  },
  {
    key: 306,
    value: "Формирование декларации по имуществу ООО",
    label: "Формирование декларации по имуществу ООО",
  },
  {
    key: 308,
    value: "Формирование единой упрощённой декларации",
    label: "Формирование единой упрощённой декларации",
  },
  {
    key: 307,
    value: "Формирование декларации 3-НДФЛ для ИП на ОСНО",
    label: "Формирование декларации 3-НДФЛ для ИП на ОСНО",
  },
  {
    key: 310,
    value: "Формирование декларации по налогу на прибыль",
    label: "Формирование декларации по налогу на прибыль",
  },
  {
    key: 311,
    value: "Формирование уведомления об исчисленных налогах",
    label: "Формирование уведомления об исчисленных налогах",
  },
];

//TODO моковый список причин прекращения обслуживания
export const deviationReason: ITaskTypeProps[] = [
  {
    value: "Причина 1",
    label: "Причина 1",
  },
  {
    value: "Причина 2",
    label: "Причина 2",
  },
  {
    value: "Причина 3",
    label: "Причина 3",
  },
];
