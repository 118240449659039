import { TagCustomColorType } from "app/types";

export type CatalogTaskStatusType = {
  status: string;
  label: string;
}

export type CatalogFilterType = {
  [key: string]: string;
}

export const taxSystem: CatalogTaskStatusType[] = [
  {status: "^УСН Доходы$", label: "УСН Доходы"},
  {status: "^УСН Доходы минус расходы$", label: "УСН Доходы минус расходы"},
  {status: "^ОСНО$", label: "ОСНО"},
];

export const taskFastFilterStatus: CatalogTaskStatusType[] = [
  {status: "BOOKED", label: "Назначены"},
  {status: "IN_PROGRESS", label: "В работе"},
  {status: "FIN_TRUE", label: "Исполнены"},
  {status: "FIN_FALSE", label: "Не исполнены"},
];

export const taskFastFilterVariant: string[] = ["BOOKED", "IN_PROGRESS", "FIN_TRUE", "FIN_FALSE"];

export const taskStatus: CatalogTaskStatusType[] = [
  {status: "NEW", label: "Новая"},
  {status: "SEARCH_RESPONSIBLE", label: "Поиск исполнителя"},
  {status: "PAUSE", label: "Приостановлена"},
  {status: "1C_SYNC", label: "Синхронизация с 1С"},
  {status: "1C_SUCCESS", label: "Отправлено в 1С"},
  {status: "1C_ERROR", label: "Ошибка 1С"}
];

export const statusVariant: Record<string, { color: TagCustomColorType; text: string }> = {
  "NEW": { color: "customProcessing", text: "Новая" },
  "SEARCH_RESPONSIBLE": { color: "customProcessing", text: "Поиск исполнителя" },
  "BOOKED": { color: "customProcessing", text: "Назначена" },
  "IN_PROGRESS": { color: "customWarning", text: "В работе" },
  "PAUSE": { color: "customWarning", text: "Приостановлена" },
  "FIN_TRUE": { color: "customSuccess", text: "Исполнена" },
  "FIN_FALSE": { color: "customError", text: "Не исполнена" },
  "1C_SYNC": { color: "customWarning", text: "Синхронизация с 1С" },
  "1C_SUCCESS": { color: "customWarning", text: "Отправлено в 1С" },
  "1C_ERROR": { color: "customError", text: "Ошибка 1С" },
  "CLOSED_BY_FNS_SUCCESS": { color: "customSuccess", text: "Принято ФНС" },
  "SENT_TO_FNS": { color: "customWarning", text: "Отправлено в ФНС" },
  "FLK_CHECK_SUCCESS": { color: "customWarning", text: "Пройден ФЛК" },
  "FLK_CHECK_ERROR": { color: "customError", text: "Ошибка ФЛК" },
  "CLIENT_CONFIRM_ERROR": { color: "customError", text: "Отказ клиента" },
  "FNS_CHECK_ERROR": { color: "customError", text: "Запрос уточнения ФНС" },
  "FNS_ACCEPTED_TO_CHECK_ERROR": { color: "customError", text: "Отказ ФНС" },
  "FNS_ACCEPT_TO_CHECK_ERROR": { color: "customError", text: "Отказ ФНС" },
  "REG_ROUTES_ERROR": { color: "customError", text: "Ошибка кода ИФНС" },
};

export const taskType: CatalogTaskStatusType[] = [
  {status: "301", label: "Онбординг"},
  {status: "100-300", label: "Исходящая"},
  {status: "302", label: "Обращение"},
  {status: "403", label: "Списание"},
  {status: "303", label: "Разметка операций"},
  {status: "204", label: "Расчет заработной платы"},
  {status: "203", label: "Расчет аванса"},
  {status: "315", label: "Наличные операции"},
];

export const taskClientsType: CatalogTaskStatusType[] = [
  {status: "^ИП", label: "ИП"},
  {status: "^ООО", label: "ООО"},
];

export const taskPriorityType: CatalogTaskStatusType[] = [
  {status: "Высокий", label: "Высокий"},
  {status: "Средний", label: "Средний"},
];

export const taskUpdateType: CatalogTaskStatusType[] = [
  {status: "true", label: "Есть обновления"},
  {status: "false", label: "Нет обновлений"},
];

export const clientStatus: CatalogTaskStatusType[] = [
  {status: "Проспект", label: "Подписана оферта"},
  {status: "Оплачено", label: "Оплачен сервис"},
  {status: "Принят на обслуживание", label: "Принят на обслуживание"},
  {status: "Снят с обслуживания", label: "Снят с обслуживания"},
];

export const taskTypeKey: CatalogFilterType = {
  "301": "Онбординг",
  "100-300": "Исходящая",
  "302": "Обращение",
  "403": "Списание",
  "303": "Разметка операций",
  "204": "Расчет заработной платы",
  "203": "Расчет аванса",
  "315": "Наличные операции",
};

export const taskStatusKey: CatalogFilterType = {
  "NEW": "Новая",
  "SEARCH_RESPONSIBLE": "Поиск исполнителя",
  "PAUSE": "Приостановлена",
  "1C_SYNC": "Синхронизация с 1С",
  "1C_SUCCESS": "Отправлено в 1С",
  "1C_ERROR": "Ошибка 1С",
  "CLOSED_BY_FNS_SUCCESS": "Принято ФНС",
  "SENT_TO_FNS": "Отправлено в ФНС",
  "FLK_CHECK_SUCCESS": "Пройден ФЛК",
  "FLK_CHECK_ERROR": "Ошибка ФЛК",
  "CLIENT_CONFIRM_ERROR": "Отказ клиента",
  "FNS_CHECK_ERROR": "Запрос уточнения ФНС",
  "FNS_ACCEPTED_TO_CHECK_ERROR": "Отказ ФНС",
  "FNS_ACCEPT_TO_CHECK_ERROR": "Отказ ФНС",
  "REG_ROUTES_ERROR": "Ошибка кода ИФНС",
};

export const taxSystemKey: CatalogFilterType = {
  "^УСН Доходы$": "УСН Доходы",
  "^УСН Доходы минус расходы$": "УСН Доходы минус расходы",
  "^ОСНО$": "ОСНО",
};

export const taskClientsTypeKey: CatalogFilterType = {
  "^ИП": "ИП",
  "^ООО": "ООО",
};

export const taskNotificationsKey: CatalogFilterType = {
  "true": "Да",
  "false": "Нет",
};
