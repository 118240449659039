import { FC, JSX, ReactNode } from "react";
import LoadingCustom from "components/ui-kit/LoadingCustom/LoadingCustom";
import { ReactComponent as CheckIcon } from "assets/icons/check_circle_icon_green.svg";
import { ReactComponent as ErrorCheckIcon} from "assets/icons/warning_circle_icon.svg";
import css from "./TaskCardNotification.module.css";
import { notification } from "antd";

interface INotificationContentProps {
  icon: ReactNode;
  label: string;
  text: string;
}

const iconMap: Record<string, ReactNode> = {
  download: <LoadingCustom fontSize={24} className="mr-4" />,
  processing: <LoadingCustom fontSize={24} className="mr-4" />,
  success: <CheckIcon className="w-[50px] h-6 mr-2" />,
  failure: <ErrorCheckIcon className="w-[50px] h-6 mr-3" />,
};

const NotificationContent: FC<INotificationContentProps> = ({
  icon,
  label,
  text
}): JSX.Element => {
  return (
    <div className="flex">
      {icon}
      <div>
        <div className={css.headerContent}>
          {label}
        </div>
        <div className={css.bodyContent}>
          {text}
        </div>
      </div>
    </div>
  );
};

const contentVariant = (value: string, label: string, text: string): JSX.Element | null => {
  const icon: ReactNode = iconMap[value];

  if (!icon) return null;

  return <NotificationContent icon={icon} label={label} text={text} />;
};

export const notificationProcessing = (value: string, label: string, text: string): void => {
  notification.open({
    key: value,
    message: null,
    duration: value !== "processing" ? 5 : null,
    description: contentVariant(value, label, text),
    style: {
      backgroundColor: "#FFF",
      width: "384px",
      borderRadius: "12px",
      boxShadow: "0px 5px 20px 0px rgba(103, 118, 140, 0.30)",
    },
    ...(value === "download" ? { closable: false } : {}),
  });
};