import {
  FC,
  JSX,
  Dispatch,
  useState,
  useEffect,
  ChangeEvent,
  SetStateAction,
} from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import {
  GetFile1CType,
  ParamsGetUuid1CType,
  ParamsGetFile1CType,
  UploadedFilesPropsType,
  TypicalTaskDecisionType,
} from "app/types";
import {
  getTaskFileC,
  getTaskUuid1C,
  getOperationMarkupFiles,
  getOperationMarkupFilesFailure,
  getProcessTaskAutomaticallyFile,
} from "actions/tasks.actions";
import ZeroSearch from "../../../../../../ClientsList/ZeroSearch/ZeroSearch";
import DownloadOrActionBlock from "./AutomaticProcessingBlocks/DownloadOrActionBlock";
import UploadedFilesListBlock from "./AutomaticProcessingBlocks/UploadedFilesListBlock";
import { ButtonCustom } from "../../../../../../ui-kit/ButtonCustom/ButtonCustom";
import { taskTypeCode } from "./utils/taskTypeCode";
import { filterOrAddTaskAction } from "./utils/filterOrAddTaskAction";
import { notificationProcessing } from "utils/Notifications/TaskCardNotifications/TaskCardNotifications";
import { taskActionsType } from "../../../../taskActions";
import dayjs, { Dayjs } from "dayjs";
import {
  Form,
  Input,
  Select,
  DatePicker,
  FormInstance,
  notification,
} from "antd";
import { declarationTopic } from "../../../../../../ClientsList/ClientCard/ClientCardItem/Tasks/contactingToClient";
import { declarationLabel } from "../../../utils/declarationLabel";

interface IAutomaticProcessingProps {
  form: FormInstance;
  taskInn: string;
  taskUuid: string;
  taskType: string;
  onFinish: (actionBody: TypicalTaskDecisionType) => void;
  fetchTask: () => NodeJS.Timeout;
  onDrawerClose: () => void;
  taskFile1CUuid: string | null;
  taskFile1CData: GetFile1CType | null;
  drawerTaskAction: string;
  dragAndDropFiles: UploadedFilesPropsType[];
  taskActionComment: string;
  uploadedFilesList: JSX.Element[] | null;
  parsingResultUuid: string;
  isAutomaticProcess: boolean;
  setDrawerTaskAction: Dispatch<SetStateAction<string>>;
  setDragAndDropFiles: Dispatch<SetStateAction<UploadedFilesPropsType[]>>;
  parsingResultStatus: number;
  file1CDownloadStatus: number;
  markupTaskFileFrom1C: UploadedFilesPropsType[] | null;
  handleTextAreaChange: (e: ChangeEvent<HTMLTextAreaElement> | string) => void;
  isSuccessDecisionType: boolean;
  remainingCharsComment: number;
  dragAndDropFilesCount: number;
  clarificationHasFiles: boolean;
  markupTaskStatusForm1C: string | null;
  setDragAndDropFilesCount: Dispatch<SetStateAction<number>>;
  setAutomaticProcessingActive: Dispatch<SetStateAction<boolean>>;
  isActionDisabled: boolean;
  declarationType: string;
  declarationEndDate: string;
  declarationStartDate: string;
}

type DatesArray = (Dayjs | null)[] | null;

const { RangePicker } = DatePicker;
const { TextArea } = Input;

const AutomaticProcessing: FC<IAutomaticProcessingProps> = ({
  form,
  taskInn,
  taskUuid,
  taskType,
  onFinish,
  fetchTask,
  onDrawerClose,
  taskFile1CUuid,
  taskFile1CData,
  drawerTaskAction,
  dragAndDropFiles,
  taskActionComment,
  uploadedFilesList,
  parsingResultUuid,
  isAutomaticProcess,
  setDrawerTaskAction,
  setDragAndDropFiles,
  parsingResultStatus,
  file1CDownloadStatus,
  markupTaskFileFrom1C,
  handleTextAreaChange,
  isSuccessDecisionType,
  remainingCharsComment,
  dragAndDropFilesCount,
  clarificationHasFiles,
  markupTaskStatusForm1C,
  setDragAndDropFilesCount,
  setAutomaticProcessingActive,
  isActionDisabled,
  declarationType,
  declarationEndDate,
  declarationStartDate,
}): JSX.Element => {
  const [endDate, setEndDate] = useState<string | null>(null);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [selectedType, setSelectedType] = useState<string>("");
  const [isFileFetched, setIsFileFetched] = useState<boolean>(true);
  const [isDownloadError, setDownloadError] = useState<boolean>(false);
  const [isFilesUploaded, setFilesUploaded] = useState<boolean>(false);
  const [isButtonDisabled, setButtonDisabled] = useState<boolean>(true);

  const dispatch = useDispatch<AppDispatch>();

  const isDownloadSuccessful: boolean = file1CDownloadStatus === 200;
  const isParsingSuccessful: boolean = parsingResultStatus === 200;
  const isDownloadErrorStatus: boolean = (!!parsingResultStatus && !isParsingSuccessful)
    || (!!file1CDownloadStatus && !isDownloadSuccessful);

  const isMarkupTask: boolean = taskType === "Разметка операций";
  const isDownloadErrorNotMarkup: boolean = isDownloadError && !isMarkupTask;
  const isAutomaticProcessing: boolean = drawerTaskAction === "Обработать задачу автоматически";
  const markupSuccessTaskStatus: boolean = markupTaskStatusForm1C === "SUCCESS";
  const markupProgressTaskStatus: boolean = markupTaskStatusForm1C === "IN_PROGRESS";
  const shouldUpdateTask: boolean = markupTaskStatusForm1C === "SUCCESS" && isAutomaticProcess;

  const hasRangeDates: boolean = !!startDate && !!endDate;
  const isAllFieldsFilled: boolean = !!selectedType && hasRangeDates || isMarkupTask;

  const declarationDefinition: string = declarationLabel(declarationType, declarationTopic);

  const automaticProcessingBody: TypicalTaskDecisionType = {
    task: taskUuid,
    comment: taskActionComment,
    task_decision_type: "auto_decision",
    common_task_type: taskTypeCode(taskType),
    files: dragAndDropFiles?.map(({ uuid }) => uuid) ?? []
  };

  const fetchingTaskBodyFrom1C: ParamsGetUuid1CType = {
    task_uuid: taskUuid ?? null,
    start_date: startDate ?? null,
    end_date: endDate ?? null,
    report_type: String(taskTypeCode(selectedType)),
    inn: taskInn ?? null
  };

  const fetchingFileBodyFrom1C: ParamsGetFile1CType = {
    task_uuid: taskUuid ? taskUuid : null,
    one_s_task_uuid: taskFile1CUuid
  };

  useEffect(() => {
    setSelectedType(declarationDefinition);

    if (declarationStartDate && declarationEndDate) {
      setStartDate(dayjs(declarationStartDate).format("YYYY-MM-DD"));
      setEndDate(dayjs(declarationEndDate).format("YYYY-MM-DD"));
    }
  }, [declarationDefinition, declarationEndDate, declarationStartDate]);

  useEffect(() => {
    if (isSuccessDecisionType) {
      notification.destroy();
    }
  }, [isSuccessDecisionType]);

  useEffect(() => {
    if (!drawerTaskAction) {
      closeActionDrawer();
    }
  }, [drawerTaskAction]);

  useEffect(() => {
    if (markupProgressTaskStatus || shouldUpdateTask) {
      fetchTask();
    }
  }, [markupProgressTaskStatus, shouldUpdateTask]);

  useEffect(() => {
    const hasNoneFetch1C: boolean = (!parsingResultStatus && !file1CDownloadStatus)
      || (!!file1CDownloadStatus && isDownloadSuccessful);

    const isErrorStatus: boolean = (!!parsingResultStatus && !isParsingSuccessful)
      || (!!file1CDownloadStatus && !isDownloadSuccessful);

    if (hasNoneFetch1C) {
      const hasMarkupTask1CData: boolean = !markupTaskStatusForm1C
        && isMarkupTask
        && !parsingResultStatus
        && !markupTaskFileFrom1C
        && isAutomaticProcessing;

      if (hasMarkupTask1CData) {
        showLoader();
        dispatch(getOperationMarkupFiles(parsingResultUuid));
      }
    }

    const isSuccessStatus: boolean = !markupTaskFileFrom1C
      && isMarkupTask
      && markupSuccessTaskStatus
      && isAutomaticProcessing;

    if (isSuccessStatus || isErrorStatus) {
      setDownloadError(true);
      setIsFileFetched(true);
      setFilesUploaded(false);
    }

    if (markupTaskFileFrom1C?.length) {
      setFilesUploaded(true);
      setIsFileFetched(true);
      setButtonDisabled(false);
      setDragAndDropFiles(markupTaskFileFrom1C);
    }
  }, [
    markupTaskFileFrom1C,
    markupTaskStatusForm1C,
    isAutomaticProcessing,
    parsingResultStatus,
    file1CDownloadStatus
  ]);

  useEffect(() => {
    if (taskFile1CData && taskFile1CData?.files?.length) {
      setFilesUploaded(true);
      setDragAndDropFiles(taskFile1CData?.files);
    }

    if (isDownloadErrorStatus) {
      setFilesUploaded(false);
      setIsFileFetched(true);
      setDownloadError(true);
    }
  }, [file1CDownloadStatus, taskFile1CData]);

  useEffect(() => {
    let intervalId: ReturnType<typeof setInterval> | undefined;

    const fetchData = (): void => {
      if (taskFile1CUuid && isDownloadSuccessful) {
        dispatch(getTaskFileC(fetchingFileBodyFrom1C));
      }
    };

    if (isDownloadSuccessful) {
      if (taskFile1CData?.status === "PROCESSING") {
        setDownloadError(false);
        setFilesUploaded(false);
        setButtonDisabled(true);

        intervalId = setInterval(fetchData, 5000);
      } else if (taskFile1CData?.files) {
        if (!taskFile1CData?.files?.length) {
          setDownloadError(true);
          setFilesUploaded(false);
        } else {
          setDownloadError(false);
          setFilesUploaded(true);
          setDragAndDropFiles(taskFile1CData.files);
        }

        if (typeof intervalId !== "undefined") {
          clearInterval(intervalId);
        }
      }
    }

    fetchData();

    return (): void => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [taskFile1CUuid, taskFile1CData?.status, file1CDownloadStatus]);

  useEffect(() => {
    if (isDownloadSuccessful && taskFile1CData?.files) {
      setButtonDisabled(!taskFile1CData?.files?.length);
      setDownloadError(!taskFile1CData?.files?.length);
      setFilesUploaded(!!taskFile1CData?.files?.length);

      if (taskFile1CData?.files?.length) {
        setDragAndDropFiles(taskFile1CData?.files);
      }
    }
  }, [taskFile1CData?.files, file1CDownloadStatus]);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    if (isParsingSuccessful) {
      setAutomaticProcessingActive(false);

      if (!markupTaskStatusForm1C) {
        setIsFileFetched(true);
        setDownloadError(false);
        setFilesUploaded(false);
      }

      if (markupProgressTaskStatus) {
        setAutomaticProcessingActive(true);
        timeoutId = setInterval(() => {
          dispatch(getOperationMarkupFiles(parsingResultUuid));
        }, 20000);
      }

      if (markupSuccessTaskStatus && markupTaskFileFrom1C === null) {
        setIsFileFetched(true);
        setDownloadError(true);
        setFilesUploaded(false);
        setButtonDisabled(false);
        setAutomaticProcessingActive(false);
      }
    }

    return () => clearInterval(timeoutId);
  }, [
    parsingResultStatus,
    markupTaskFileFrom1C,
    markupTaskStatusForm1C,
  ]);

  const onAutomaticProcessingFinish = (): void => {
    onFinish(automaticProcessingBody);

    setDownloadError(false);
    setButtonDisabled(true);
    setAutomaticProcessingActive(true);
    notificationProcessing(
      "processing",
      "Файлы ожидают обработки",
      "Действие подтверждено, файлы в обработке. Это займет некоторое время, пожалуйста, подождите."
    );
  };

  const showLoader = (): void => {
    setDownloadError(false);
    setButtonDisabled(true);
    setIsFileFetched(false);
  };

  const handleSelectType = (value: string): void => {
    setSelectedType(value);
  };

  const handleDateChange = (dates: DatesArray): void => {
    if (dates?.[0] && dates?.[1]) {
      setStartDate(dates[0].format("YYYY-MM-DD"));
      setEndDate(dates[1].format("YYYY-MM-DD"));
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const downloadFileAutomatically = (): void => {
    if (isMarkupTask) {
      setButtonDisabled(true);
      setAutomaticProcessingActive(true);
      dispatch(getProcessTaskAutomaticallyFile(taskUuid))
        .then(() => showLoader())
        .finally(() => {
          setTimeout(() => {
            dispatch(getOperationMarkupFilesFailure(0));
            setButtonDisabled(false);
          }, 1000);
        });
    } else {
      setIsFileFetched(false);
      dispatch(getTaskUuid1C(fetchingTaskBodyFrom1C));
    }
  };

  const closeActionDrawer = (): void => {
    onDrawerClose();
    setIsFileFetched(true);
    setFilesUploaded(false);
    setDownloadError(false);
  };

  const openManualProcessing = (): void => {
    handleTextAreaChange("");
    setDrawerTaskAction("Обработать задачу вручную");
  };

  const taskTypeDefaultValue = (): string | null => {
    switch (taskType) {
      case "Разметка операций":
        return "Разметка операций";
      case "Декларация":
        return declarationDefinition;
      default:
        return null;
    }
  };

  return (
    <Form
      form={form}
      className="m-0"
      name="automaticProcessing"
      layout="vertical"
      onFinish={onAutomaticProcessingFinish}
    >
      <Form.Item className="mb-4" name="taskType">
        <Form.Item
          name="taskType"
          label="Тип задачи"
          className="mb-4"
          validateStatus={!taskType ? "error" : ""}
          initialValue={taskTypeDefaultValue()}
          required
        >
          <Select
            id="taskType"
            size="large"
            allowClear
            onChange={handleSelectType}
            placeholder="Тип задачи"
            notFoundContent={<ZeroSearch dataFilter />}
            options={filterOrAddTaskAction(taskType, taskActionsType)}
          />
        </Form.Item>
        <div>
          {!isMarkupTask && (
            <>
              <Form.Item
                name="datePeriod"
                label="Период"
                className="mb-4"
                required
                initialValue={[
                  declarationStartDate && dayjs(declarationStartDate),
                  declarationEndDate && dayjs(declarationEndDate),
                ]}
              >
                <RangePicker
                  id="datePeriod"
                  size="large"
                  className="w-full"
                  format="DD.MM.YYYY"
                  placeholder={["С даты", "По дату"]}
                  maxDate={dayjs()}
                  onChange={(dates: DatesArray): void | null => handleDateChange(dates)}
                />
              </Form.Item>
              <Form.Item
                name="automaticProcessComment"
                label="Комментарий"
                className="mb-4"
              >
                <TextArea
                  id="automaticProcessComment"
                  rows={25}
                  maxLength={2000}
                  className="text-base font-normal leading-5 text-gray-900"
                  placeholder="Ваш комментарий клиенту"
                  onChange={handleTextAreaChange}
                />
                <div className="text-xs font-normal leading-100 counter-gray-color text-right">
                  {remainingCharsComment}/2000
                </div>
              </Form.Item>
            </>
          )}
          <DownloadOrActionBlock
            isMarkupTask={isMarkupTask}
            isFileFetched={isFileFetched}
            taskFile1CData={taskFile1CData}
            isFilesUploaded={isFilesUploaded}
            isDownloadError={isDownloadError}
            isAllFieldsFilled={isAllFieldsFilled}
            isParsingSuccessful={isParsingSuccessful}
            parsingResultStatus={parsingResultStatus}
            isDownloadSuccessful={isDownloadSuccessful}
            file1CDownloadStatus={file1CDownloadStatus}
            markupTaskFileFrom1C={markupTaskFileFrom1C}
            markupTaskStatusForm1C={markupTaskStatusForm1C}
            downloadFileAutomatically={downloadFileAutomatically}
            isActionDisabled={isActionDisabled}
          />
          <UploadedFilesListBlock
            uploadedFilesList={uploadedFilesList}
            setDragAndDropFiles={setDragAndDropFiles}
            dragAndDropFilesCount={dragAndDropFilesCount}
            clarificationHasFiles={clarificationHasFiles}
            setDragAndDropFilesCount={setDragAndDropFilesCount}
          />
        </div>
      </Form.Item>
      <Form.Item className="text-right">
        <ButtonCustom
          className="mr-2"
          size="large"
          type="default"
          ghost
          text="Отменить"
          onClick={closeActionDrawer}
        />
        <ButtonCustom
          size="large"
          type="primary"
          text={isDownloadErrorNotMarkup ? "Обработать вручную" : "Подтвердить"}
          htmlType={isDownloadErrorNotMarkup ? undefined : "submit"}
          onClick={() => isDownloadErrorNotMarkup && openManualProcessing()}
          disabled={(!isDownloadError && isButtonDisabled) || isActionDisabled}
        />
      </Form.Item>
    </Form>
  );
};

export default AutomaticProcessing;
